<template>
  <div class="service-section">
    <line-on-sides v-if="service.title" class="mb-8">
      {{ service.title }}
    </line-on-sides>
    <v-row v-if="service.text || service.imageURL" align="center" justify="center">
      <v-col
        v-if="service.text"
        :order="service.text_order || 0"
        :class="textClass"
        cols="12"
        :md="imageURL ? 6 : 12"
      >
        {{ service.text }}
      </v-col>
      <v-col
        v-if="imageURL"
        class="text-column-center text-center"
        :order="service.media_order || 0"
        cols="12"
        :md="service.text ? 6 : 12"
      >
        <v-img
          cover
          position="center center"
          max-height="300"
          :src="`${imageURL}`"
        ></v-img>
      </v-col>
    </v-row>
    <div class="description">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import LineOnSides from "@/components/ui/LineOnSides.vue";
export default {
  name: "ServiceSection",
  components: { LineOnSides },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageURL() {
      return this.service.media?.url || "";
    },
    textClass() {
      if (!this.service.text_class) {
        return "text-center";
      }

      return this.service?.text_class;
    },
  },
};
</script>

<style lang="scss" scoped>

.service-section {
  margin: 2rem 0;
  .image-container {
    width: 100%;
    height: 300px;
  }

  .description {
    font-weight: 500;
  }
}

</style>
