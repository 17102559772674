<template>
  <div id="no02" class="wrapper">
    <div class="boxes">
      <div v-for="item in items" :key="item.id">
        <div v-if="!item.image" :class="[item.class, ' box']" :key="item.id">{{item.text}}</div>
        <img alt="company logo" v-else loading="lazy" :class="[item.class, ' box']" :src="item.image.url" />
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
  name: "EndlessCarousel",
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  mounted() {
    this.animate();
  },
  methods: {
    animate() {
      gsap.set(".wrapper", { xPercent: -50 });
      const numberOfItems = this.items.length;
      const isScreenVeryWidth =
        numberOfItems * 250 < this.$vuetify.breakpoint.width;

      const boxWidth = isScreenVeryWidth
          ? this.$vuetify.breakpoint.width / numberOfItems
          : 250,
        totalWidth = isScreenVeryWidth
          ? this.$vuetify.breakpoint.width
          : boxWidth * numberOfItems,
        time = isScreenVeryWidth ? 15 : 12,
        no02 = document.querySelectorAll("#no02 .box"),
        dirFromLeft = "+=" + totalWidth,
        dirFromRight = "-=" + totalWidth;

      const mod = gsap.utils.wrap(0, totalWidth);

      gsap.set(no02, {
        x: function(i) {
          return i * boxWidth;
        },
      });

      const action = gsap.timeline().to(no02, {
        x: dirFromRight,
        modifiers: {
          x: (x) => mod(parseFloat(x)) + "px",
        },
        duration: time,
        ease: "none",
        repeat: -1,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  height: 300px;
  left: 50%;
  background: white;
  display: flex;
  align-items: center;
}

.wrapper .box {
  position: absolute;
  width: 200px;
  color: black;
  font-size: 32px;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.wrapper .boxes {
  position: relative;
  left: -250px;
  height: 200px;
}
</style>
