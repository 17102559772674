<template>
  <div>
    <v-container>
      <v-row class="our-services">
        <v-col class="our-services-title" md="6">
          <h1>{{ servicesPageContent.title }}</h1>
          <v-btn
            class="white--text"
            color="#2EB359"
            elevation="2"
            x-large
            :to="{ path: '/contact-us', hash: '#form' }"
          >
            Schedule Consultation
          </v-btn>
        </v-col>
        <v-col md="6">
          <div class="image-container">
            <v-img :src="`${mainImageURL}`"></v-img>
          </div>
        </v-col>
      </v-row>
      <div class="our-services-description text-center my-8">
        {{ servicesPageContent.description }}
      </div>
      <line-on-sides class="my-8">
        Heading
      </line-on-sides>

      <v-row v-if="(servicesPageContent.services || []).length">
        <v-col
          v-for="(service, index) in servicesPageContent.services"
          :key="index"
          cols="12"
          md="4"
        >
          <service-card :service="service"></service-card>
        </v-col>
      </v-row>
    </v-container>

    <div class="banner text-h1 text-column-center mt-8">BANNER</div>

    <v-container v-if="servicesPageContent.service_sections.length">
      <service-section
        v-for="(service, index) in servicesPageContent.service_sections"
        :key="index"
        :service="service"
      >
        <v-row v-if="service.showScheduleButton" class="justify-center my-8">
          <v-btn
            class="white--text"
            color="#2EB359"
            elevation="2"
            x-large
            :to="{ path: '/contact-us', hash: '#form' }"
          >
            Schedule Consultation
          </v-btn>
        </v-row>
      </service-section>
    </v-container>

    <v-row>
      <endless-carousel v-if="trustedByList.length" :items="trustedByList" />
    </v-row>

    <v-container>
      <v-container v-if="servicesPageContent.service_sections.length">
        <service-section
          v-for="(service, index) in servicesPageContent.service_sections_2"
          :key="index"
          :service="service"
        >
          <v-row v-if="service.showScheduleButton" class="justify-center my-8">
            <v-btn
              class="white--text"
              color="#2EB359"
              elevation="2"
              x-large
              :to="{ path: '/contact-us', hash: '#form' }"
            >
              Schedule Consultation
            </v-btn>
          </v-row>
        </service-section>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import LineOnSides from "@/components/ui/LineOnSides.vue";
import ServiceCard from "@/components/pages/services/ServiceCard.vue";
import EndlessCarousel from "@/components/ui/EndlessCarousel.vue";
import { mapActions, mapGetters } from "vuex";
import ServiceSection from "@/components/pages/services/ServiceSection.vue";

export default {
  name: "ServicesPage",
  components: {
    LineOnSides,
    ServiceCard,
    EndlessCarousel,
    ServiceSection,
  },
  data() {
    return {
      servicesMocked: [
        {
          title: "test",
          text: "lorem ipsum",
          // text_classes: "text-left",
          media: {
            url: "https://picsum.photos/seed/picsum/536/354",
          },
          show_schedule_button: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("servicesPageStore", ["servicesPageContent"]),
    ...mapGetters("commonContent", ["trustedByList"]),
    mainImageURL() {
      return this.servicesPageContent?.mainMedia?.url || "";
    },
  },
  mounted() {
    this.getContent();
    this.getTrustedByList();
  },
  methods: {
    ...mapActions("servicesPageStore", ["getContent"]),
    ...mapActions("commonContent", ["getTrustedByList"]),
  },
};
</script>

<style lang="scss" scoped>
.our-services {
  &-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      margin-bottom: 1em;
    }
  }
  .image {
    height: 300px;
    background: red;
  }
}

.our-services-description {
  max-width: 30rem;
  margin: 0 auto;
}

.banner {
  background: gray;
  text-align: center;
  color: white;
  min-height: 200px;
}

.design-image {
  background: red;
  min-height: 300px;
  text-align: center;
  color: white;
}

.text-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.brand-container {
  background: red;
  min-height: 100px;
}

.image-container {
  width: 100%;
  height: 100%;

  img {
    max-width: 100%;
    object-fit: contain;
  }
}
</style>
